// CAROUSELS PRO
.carousel-multi-item {
  margin-bottom: $carousel-multi-item-mb;

  .carousel-inner {
    padding-top: $carousel-multi-item-inner-pt;
    padding-bottom: $carousel-multi-item-inner-pb;
  }

  .carousel-inner .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block;
  }

  .carousel-inner.v-2 .carousel-item.active,
  .carousel-inner.v-2 .carousel-item-next,
  .carousel-inner.v-2 .carousel-item-prev {
    display: flex;
  }

  .carousel-inner.v-2 .carousel-item-right.active,
  .carousel-inner.v-2 .carousel-item-next {
    transform: translateX(100%);
    @include media-breakpoint-up ('md') {
      transform: translateX(33%);
    }
  }

  .carousel-inner.v-2 .carousel-item-left.active,
  .carousel-inner.v-2 .carousel-item-prev {
    transform: translateX(-100%);
    @include media-breakpoint-up ('md') {
      transform: translateX(-33%);
    }
  }

  .carousel-inner.v-2 .carousel-item-right,
  .carousel-inner.v-2 .carousel-item-left {
    transform: translateX(0%);
  }

  .carousel-indicators li {
    width: $carousel-multi-item-indicators-li-width;
    max-width: $carousel-multi-item-indicators-li-max-width;
    height: $carousel-multi-item-indicators-li-height;
    margin-bottom: $carousel-multi-item-indicators-li-mb;
    background-color: $primary-color;
  }

  .carousel-indicators .active {
    width: $carousel-multi-item-indicators-active-width;
    max-width: $carousel-multi-item-indicators-active-max-width;
    height: $carousel-multi-item-indicators-active-height;
    background-color: $primary-color;
    border-radius: $carousel-multi-item-indicators-active-border-radius;
  }

  .controls-top {
    margin-bottom: $carousel-multi-item-controls-top-mb;
    text-align: center;

    .btn-floating {
      background: $primary-color;
    }
  }

  .carousel-indicators {
    margin-bottom: $carousel-multi-item-indicators-mb;
  }

  .card-cascade.narrower {
    margin-top: $carousel-multi-item-card-cascade-narrower-mt;
    margin-bottom: $carousel-multi-item-card-cascade-narrower-mb;
  }

  @media (min-width: 768px) {
    [class*="col-"] {
      float: left;
    }
  }
}

@media only screen and (max-width: $medium-screen) {
  .carousel-multi-item {
    .carousel-indicators li {
      margin-bottom: $carousel-multi-item-medium-screen-mb;
    }
  }
}

// Carousel with thumbnails
.carousel-thumbnails {
  .carousel-indicators {
    position: static;
    left: initial;
    width: initial;
    margin-right: initial;
    margin-left: initial;
    overflow-x: auto;
    white-space: nowrap;

    > li {
      width: initial;
      height: initial;
      text-indent: initial;

      .active {
        img {
          opacity: $carousel-thumbnails-indicators-active-opacity;
        }
      }
    }
  }
}

// Fix for testimontials (class use in bs js)
.wrapper-carousel-fix .carousel-fade .active {

  &.carousel-item-left,
  &.carousel-item-right {
    transition: transform .6s ease;
    transition-property: opacity;
  }
}
