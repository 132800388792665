// Testimonials section
.testimonial-carousel {
  .carousel-control {
    top: $testimonial-carousel-control-top;
    background-image: none;
    transform: $testimonial-carousel-control-transform;
    &::before {
      font-size: $testimonial-carousel-control-font-size;
      color: $testimonial-carousel-control-color;
      transition: $testimonial-carousel-control-hover-transition;
    }
    &:hover {
      &::before {
        color: $testimonial-carousel-control-hover-color;
        transition: $testimonial-carousel-control-hover-transition;
      }
    }
    &.left {
      left: $testimonial-carousel-control-mx;
      &::before {
        content: "\2039";
      }
    }
    &.right {
      right: $testimonial-carousel-control-mx;
      &::before {
        content: "\203a";
      }
    }
  }
  .testimonial {
    margin-right: $testimonial-carousel-mx;
    margin-left: $testimonial-carousel-mx;
    .avatar {
      img {
        max-width: $testimonial-carousel-avatar-max-width;
        box-shadow: $z-depth-1-half;
      }
    }
  }
}
