// Your custom skin
// Skins
$skins: () !default;
$skins: map-merge(
  (
    "test": (
      "skin-primary-color":      #fff,
      "skin-navbar":             #fff,
      "skin-footer-color":       #fff,
      "skin-flat":               #fff,
      "skin-accent":             #fff,
      "skin-sidenav-item":       #fff,
      "skin-sidenav-item-hover": #fff,
      "skin-gradient-start":     #fff,
      "skin-gradient-end":       #fff,
      "skin-mask-slight":        #fff,
      "skin-mask-light":         #fff,
      "skin-mask-strong":        #fff,
      "skin-sn-child":           #fff,
      "skin-btn-primary":        #fff,
      "skin-btn-secondary":      #fff,
      "skin-btn-default":        #fff,
      "skin-text":               #fff
    )
  ),
  $skins
);

