.md-tooltip,
.md-tooltip-main,
.md-tooltip-email {
  &.show {
    opacity: 1 !important;
  }
}

.md-inner {
  min-height: 24px !important;
  padding: 7px 8px;
  font-size: 10px;
  background: rgba(97, 97, 97, .9) !important;
  border-radius: 4px;
}

.md-arrow {
  display: none;
}

.md-inner-main {
  min-height: 32px !important;
  padding: 9px 16px;
  font-size: 14px;
  background: rgba(97, 97, 97, .9) !important;
  border-radius: 4px;
}

.md-tooltip,
.md-tooltip-main {
  line-height: 1;
}

.md-inner-email {
  padding-right: 12px;
  padding-left: 12px;
  font-size: 12px;
  background-color: #232f34 !important;
  border-radius: 25px;
}
