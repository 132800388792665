// Scrollspy
.nav-pills {
  &.default-pills {
    .nav-item {
      a {
        color: $Scrollspy-color-mdb;
        text-align: left;
        @include scrollspy-border-radius(0);

        &:hover {
          color: $mdb-color-base;
          background-color: transparent;
          border-left: $scrollspy-navpills-naviteam-hover-border-left solid $mdb-color-base;
          @include scrollspy-font-weight(500);
        }

        &.active {
          color: $mdb-color-base;
          background-color: transparent;
          border-left: $scrollspy-navpills-naviteam-active-border-left solid $mdb-color-base;
          box-shadow: none;
          @include scrollspy-font-weight(500);

          &:hover,
          &:active,
          &:focus {
            color: $mdb-color-base;
            background-color: transparent;
            @include scrollspy-font-weight(500);
          }
        }
      }
    }
  }
}

.nav-pills .nav-item + .nav-item {
  margin-left: 0;
}

@media only screen and (max-width: 991px) {

  .sticky,
  .sticky-placeholder {
    display: none;
  }
}

#scrollspy {
  @include scroll-width(100%);
}

@media only screen and (max-width: $medium-screen) {

  .col-md-4 .sticky,
  .col-lg-4 .sticky,
  .col-xl-4 .sticky {
    @include scroll-width(12.5rem);
  }
}

@media only screen and (min-width: $medium-screen) {

  .col-md-4 .sticky .col-lg-4 .sticky,
  .col-xl-4 .sticky {
    @include scroll-width(25rem);
  }
}


@media only screen and (min-width: $large-screen) {
  .col-md-4 .sticky {
    @include scroll-width(18.75rem);
  }
}

@media only screen and (min-width: $sidenav-breakpoint) {
  .col-md-4 .sticky {
    @include scroll-width(21.875rem);
  }
}

// Dotted scrollspy

.dotted-scrollspy {
  position: fixed;
  top: $scrollspy-dotted-top;
  right: 0;
  display: block;
  background: $scrollspy-dotted-bg;
  transform: $scrollspy-dotted-transform;
  @include scrollspy-border-radius-4rows(.125rem, 0, 0, .125rem);

  li {
    display: block;
    padding: $scrollspy-padding;

    &:first-child {
      padding-top: $scrollspy-padding-top;
    }

    &:last-child {
      padding-bottom: $scrollspy-padding-bottom;
    }

    a {
      padding: $scrollspy-padding-half;

      span {
        display: block;
        background-color: rgba($white, .54);
        @include scroll-width(.5rem);
        @include scroll-height(.5rem);
        @include scrollspy-border-radius(50%);
      }

      &.active {
        span {
          background: $white;
        }
      }
    }
  }
}

.scrollspy-example {
  position: relative;
  padding: $scrollspy-example-padding;
  overflow-y: scroll;
  @include scroll-height(12.5rem);
}
