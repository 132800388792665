// Cards basic
.card {
  &.card-image {
    width: 100%;
    background-position: center;
    background-size: cover;
    [class*="rgba-"] {
      border-radius: $md-card-border-radius;
    }
  }
  // Cascading cards
  &.card-cascade {
    .view {
      &.view-cascade {
        border-radius: $md-card-border-radius;
        box-shadow: $z-depth-1-half;
        &.gradient-card-header {
          padding: $card-cascade-header-gradient-py $card-cascade-header-gradient-px;
          color: $white-base;
          text-align: center;
          .card-header-title {
            font-weight: 500;
          }
          .btn-floating {
            background-color: $card-cascade-header-gradient-btn-floating-bgc;
          }
        }
      }
    }
    &.wider {
      background-color: transparent;
      box-shadow: none;
      .view {
        &.view-cascade {
          z-index: 2;
        }
      }
      .card-body {
        &.card-body-cascade {
          z-index: 1;
          margin-right: $card-cascade-wider-card-body-mx;
          margin-left: $card-cascade-wider-card-body-mx;
          background: $card-cascade-wider-card-body-bg;
          border-radius: 0 0 $md-card-border-radius $md-card-border-radius;
          box-shadow: $z-depth-1;
          .card-footer {
            margin-right: -1.25rem;
            margin-left: -1.25rem;
          }
        }
      }
      &.reverse {
        .card-body {
          &.card-body-cascade {
            z-index: 3;
            margin-top: $card-cascade-reverse-mt;
            border-radius: $md-card-border-radius;
            box-shadow: $z-depth-1-half;
          }
        }
      }
    }
    &.narrower {
      margin-top: $card-cascade-narrower-mt;
      .view {
        &.view-cascade {
          margin-top: $card-cascade-narrower-view-mt;
          margin-right: $card-cascade-narrowe-view-mx;
          margin-left: $card-cascade-narrowe-view-mx;
        }
      }
    }
  }
  .btn-action {
    margin-top: $card-btn-action-my;
    margin-bottom: $card-btn-action-my;
  }
  .activator {
    position: absolute;
    right: 0;
    font-size: $card-activator-font-size;
  }
  .card-reveal {
    position: absolute;
    top: 100%;
    z-index: 1;
    display: none;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .content {
      position: relative;
    }
  }
  &.ovf-hidden {
    overflow: hidden;
  }
  .card-share {
    position: relative;
    .social-reveal {
      position: absolute;
      top: $card-share-social-reveal-top;
      right: $card-share-social-reveal-right;
      width: auto;
      opacity: 0;
      transition: $card-share-social-reveal-transition;
      transform: translateZ(0);
      transform: translateX(80px);
    }
    .social-reveal-active {
      z-index: 11;
      opacity: 1;
      transition: $card-share-social-reveal-transition;
      transform: translateZ(0);
      transform: translateX(-48px);
    }
  }
}

// Title and close button
.card .card-reveal .card-title,
.card-wrapper .card-rotating .card-title {
  cursor: pointer;
}
.card .card-reveal .card-title i,
.card-wrapper .card-rotating .card-title i {
  position: absolute;
  right: $card-reveal-title-i-right;
  color: $card-reveal-title-i-color;
}

// Rotating card
.card-wrapper {
  position: relative;
  width: 100%;
  margin: 0;
  perspective: $card-wrapper-perspective;
  .card-up {
    height: $card-up-img-size;
    overflow: hidden;
    img {
      vertical-align: middle;
    }
  }
  .avatar {
    display: block;
    width: $card-up-size;
    margin-top: $card-avatar-margin-top;
    overflow: hidden;
    img {
      width: 100%;
      background: none repeat scroll 0 0 $white-base;
      border: 5px solid $white-base;
    }
  }
  .card-rotating {
    height: 100%;
    transition: .5s;
    transform-style: preserve-3d;
    .face {
      position: absolute;
      z-index: 2;
      width: 100%;
      background: $white-base;
      backface-visibility: hidden;
    }
    &.flipped {
      transform: rotateY(180deg);
    }
    .front {
      z-index: 1;
    }
    .back {
      transform: rotateY(-180deg);
    }
    .front,
    .back {
      transition: 1s;
    }
  }
}

// Testimonial card
.testimonial-card {
  .card-up {
    height: $card-up-size;
    overflow: hidden;
    border-top-left-radius: $md-card-border-radius;
    border-top-right-radius: $md-card-border-radius;
  }
  .avatar {
    width: $card-up-size;
    margin-top: $card-avatar-margin-top;
    overflow: hidden;
    border: 5px solid $white-base;
    border-radius: $border-radius-circle;
  }
  .avatar img {
    width: 100%;
  }
  .card-body {
    text-align: center;
  }
}
