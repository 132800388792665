body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bg {
    background-image: url("/bg.png");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.pointer {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.pointer:hover {
    color: #2de7ff !important;
}

li.nav-item {
    text-transform: uppercase;
    font-size: 0.8em !important;
    font-weight: 400;
}

.navbar.navbar-light .navbar-nav .nav-item.active>.nav-link {
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.3) !important
}

#selectSearchInput {
    padding: 0.5rem !important;
}

.swal2-styled.swal2-confirm {
    background-color: #00cee8 !important;
    border: none !important;
    border-radius: .0 !important;
}

div.dataTables_wrapper div.dataTables_info,
div.dataTables_wrapper div.dataTables_paginate {
    font-size: 0.9rem !important;
}