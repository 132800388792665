// Miscellenous
// Advanced tables
.table {
  fieldset {
    &.form-check {
      margin-bottom: 0;
    }
    label {
      &.form-check-label {
        height: $advanced-table-fieldset-label-height;
      }
    }
  }
}

// Product table
.product-table {
  img {
    min-width: $product-table-img-min-width;
    max-height: $product-table-img-max-height;
  }
  td {
    vertical-align: middle;
  }
}

.streak {
  position: relative;
  display: block;
  height: 250px;
  overflow: hidden;
  &.streak-md {
    height: 400px;
    @media (max-width: 736px) {
      height: 300px;
    }
  }
  &.streak-lg {
    height: 650px;
    @media (max-width: 450px) {
      height: 900px;
    }
  }
  &.streak-long {
    height: 200px;
    @media (max-width: 450px) {
      height: 620px;
    }
  }
  &.streak-long-2 {
    height: 400px;
    @media (max-width: 450px) {
      height: 700px;
    }
  }
  &.streak-photo {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (min-width: 1366px) {
      background-attachment: fixed;
    }
  }
  &.no-flex {
    height: auto;
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
}

.collapse-content {
  table,
  p {
    &.collapse {
      &:not(.show) {
        display: flex;
        height: 2.65rem;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    &.collapsing {
      min-height: 2.65rem;
    }
  }
  a {
    &.collapsed {
      &:after {
        content: "Read More";
      }
    }
    &:not(.collapsed) {
      &:after {
        content: "Read Less";
      }
    }
  }
}
