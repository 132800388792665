// Contact section
.contact-section {
  .form {
    .btn-floating {
      position: relative;
      bottom: $contact-section-form-btn-floating-bottom;
      float: right;
      margin-right: 0;
    }
  }
  .contact {
    background-color: $contact-section-bgc;
    border-radius: 0 $md-card-border-radius $md-card-border-radius 0;
    .fas,
    .fab,
    .far {
      color: $contact-section-fa-color;
    }
  }
}
