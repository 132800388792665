// Parallax
.jarallax-keep-img {
  position: relative;
  z-index: 0;
}

.jarallax-keep-img > .jarallax-img {
  position: relative;
  z-index: -100;
  display: block;
  max-width: 100%;
  height: auto;
}

.jarallax {
  min-height: 600px;
}

.jarallax > .jarallax-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;

  /* support for plugin https://github.com/bfred-it/object-fit-images */
  // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
  font-family: "object-fit: cover;";
}
