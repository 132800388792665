@import "../core/_colors";

.timeline-main {
  .stepper {
    &.stepper-vertical {
      &.timeline {
        li {
          a {
            left: 50%;
            padding: 0 24px;

            @media (max-width: 450px) {
              left: 6%;
            }

            @media (min-width: 451px) and (max-width: 1025px) {
              left: 6%;
            }

            .circle {
              position: absolute;
              top: 16px;
              z-index: 2;
              width: 50px;
              height: 50px;
              margin-left: -50px;
              font-size: 1.4em;
              line-height: 50px;
              text-align: center;
              background-color: #ccc;
            }
          }

          .step-content {
            position: relative;
            float: left;
            width: 45%;
            border-radius: 2px;

            @media (max-width: 450px) {
              left: 3rem;
              float: right;
              width: 80%;
              margin-right: 3rem;
              margin-bottom: 2rem;
            }

            @media (min-width: 451px) and (max-width: 1025px) {
              left: 3rem;
              float: right;
              width: 85%;
              margin-right: 3rem;
              margin-bottom: 2rem;
            }

            &:before {
              position: absolute;
              top: 26px;
              right: -15px;
              display: inline-block;
              content: " ";
              border-top: 15px solid transparent;
              border-right: 0 solid #e0e0e0;
              border-bottom: 15px solid transparent;
              border-left: 15px solid #e0e0e0;

              @media (max-width: 450px) {
                right: auto;
                left: -15px;
                border-right-width: 15px;
                border-left-width: 0;
              }

              @media (min-width: 451px) and (max-width: 1025px) {
                right: auto;
                left: -15px;
                border-right-width: 15px;
                border-left-width: 0;
              }
            }

            &:after {
              position: absolute;
              top: 27px;
              right: -14px;
              display: inline-block;
              content: " ";
              border-top: 14px solid transparent;
              border-right: 0 solid #fff;
              border-bottom: 14px solid transparent;
              border-left: 14px solid #fff;

              @media (max-width: 450px) {
                right: auto;
                left: -14px;
                border-right-width: 14px;
                border-left-width: 0;
              }

              @media (min-width: 451px) and (max-width: 1025px) {
                right: auto;
                left: -14px;
                border-right-width: 14px;
                border-left-width: 0;
              }
            }
          }

          &.timeline-inverted {
            align-items: flex-end;

            .step-content {
              float: right;

              &:before {
                right: auto;
                left: -15px;
                border-right-width: 15px;
                border-left-width: 0;
              }

              &:after {
                right: auto;
                left: -14px;
                border-right-width: 14px;
                border-left-width: 0;
              }
            }
          }

          &:not(:last-child):after {
            position: absolute;
            top: 65px;
            left: 50%;
            width: 3px;
            margin-left: -1.5px;
            content: " ";
            background-color: #e0e0e0;

            @media (max-width: 450px) {
              left: 6%;
            }

            @media (min-width: 451px) and (max-width: 1025px) {
              left: 6%;
            }
          }

          @media (max-width: 1025px) {
            align-items: flex-end;
          }
        }

        &.colorful-timeline {
          li {
            .step-content {
              &:before {
                top: 1rem;
                border-right: 0 solid #d32f2f;
                border-left: 15px solid #d32f2f;

                @media (max-width: 450px) {
                  right: auto;
                  left: -15px;
                  border-right-width: 15px;
                  border-left-width: 0;
                }

                @media (min-width: 451px) and (max-width: 1025px) {
                  right: auto;
                  left: -15px;
                  border-right-width: 15px;
                  border-left-width: 0;
                }
              }

              &:after {
                top: 1rem;
                border-right: 0 solid #d32f2f;
                border-left: 14px solid #d32f2f;

                @media (max-width: 450px) {
                  right: auto;
                  left: -14px;
                  border-right-width: 14px;
                  border-left-width: 0;
                }

                @media (min-width: 451px) and (max-width: 1025px) {
                  right: auto;
                  left: -14px;
                  border-right-width: 14px;
                  border-left-width: 0;
                }
              }

              .timeline-header {
                border-top-left-radius: 2px;
                border-top-right-radius: 2px;
              }
            }

            &.timeline-inverted {
              .step-content {
                &:before {
                  left: -15px;
                  border-right-width: 15px;
                  border-left-width: 0;
                }

                &:after {
                  left: -14px;
                  border-right-width: 14px;
                  border-left-width: 0;
                }
              }
            }

            &:not(:last-child):after {
              background-color: #d32f2f;
            }
          }
        }

        &.timeline-animated {
          .more-padding {
            padding-right: 100px;
            padding-left: 100px;
          }

          li {
            transition: all .7s ease-in-out;
          }
        }

        &.timeline-simple {
          li {
            a {
              padding: 0;

              .circle {
                position: absolute;
                top: 16px;
                z-index: 2;
                width: 23px;
                height: 23px;
                margin-left: -12px;
                font-size: 1.4em;
                line-height: 23px;
                text-align: center;
                background-color: #ccc;
              }
            }

            .step-content {
              position: relative;
              float: left;
              width: 45%;
              border-radius: 2px;

              @media (max-width: 450px) {
                left: 3rem;
                float: right;
                width: 80%;
                margin-right: 3rem;
                margin-bottom: 2rem;
              }

              @media (min-width: 451px) and (max-width: 1025px) {
                left: 3rem;
                float: right;
                width: 85%;
                margin-right: 3rem;
                margin-bottom: 2rem;
              }

              &:before {
                position: absolute;
                top: 15px;
                display: inline-block;
                content: " ";
                border-top: 15px solid transparent;
                border-right: 0 solid #e0e0e0;
                border-bottom: 15px solid transparent;
                border-left: 15px solid #e0e0e0;

                @media (max-width: 450px) {
                  right: auto;
                  left: -15px;
                  border-right-width: 15px;
                  border-left-width: 0;
                }

                @media (min-width: 451px) and (max-width: 1025px) {
                  right: auto;
                  left: -15px;
                  border-right-width: 15px;
                  border-left-width: 0;
                }
              }

              &:after {
                position: absolute;
                top: 15px;
                display: inline-block;
                content: " ";
                border-top: 14px solid transparent;
                border-right: 0 solid #fff;
                border-bottom: 14px solid transparent;
                border-left: 14px solid #fff;

                @media (max-width: 450px) {
                  right: auto;
                  left: -14px;
                  border-right-width: 14px;
                  border-left-width: 0;
                }

                @media (min-width: 451px) and (max-width: 1025px) {
                  right: auto;
                  left: -14px;
                  border-right-width: 14px;
                  border-left-width: 0;
                }
              }

              &.hoverable {
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);

                &:hover {
                  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
                }
              }
            }

            &:not(:last-child):after {
              top: 32px;
              width: 2px;
              height: 100%;
              background-color: #9e9e9e;
            }

            &.timeline-inverted {
              .step-content {
                &:before {
                  left: -15px;
                  border-right-width: 15px;
                  border-left-width: 0;
                }

                &:after {
                  left: -14px;
                  border-right-width: 14px;
                  border-left-width: 0;
                }
              }
            }
          }
        }

        &.timeline-images {
          li {
            a {
              .circle {
                font-size: 1rem;
              }
            }

            &:not(:last-child):after {
              background-color: #26c6da;
            }
          }
        }

        &.timeline-basic,
        &.colorful-timeline,
        &.timeline-animated {
          li {
            a {
              @media (max-width: 450px) {
                left: 8%;
              }

              .circle {
                @media (max-width: 450px) {
                  top: 26px;
                  width: 30px;
                  height: 30px;
                  margin-left: -35px;
                  font-size: .9em;
                  line-height: 30px;
                }
              }
            }

            &:not(:last-child):after {
              @media (max-width: 450px) {
                top: 55px;
                left: 9%;
                height: 100%;
              }
            }
          }
        }

        &.timeline-simple,
        &.timeline-images {
          li {
            a {
              @media (max-width: 450px) {
                left: 10%;
              }

              .circle {
                @media (max-width: 450px) {
                  top: 18px;
                }
              }
            }

            &:not(:last-child):after {
              @media (max-width: 450px) {
                left: 10%;
              }
            }
          }
        }
      }
    }
  }

  .timeline-light {
    padding-left: 1em;
    font-size: 20px;
    line-height: 1.5em;
    list-style: none;

    .timeline-light-item {
      position: relative;

      .timeline-light-item-tail {
        position: absolute;
        bottom: 0;
        left: 14px;
        width: 0;
        height: 100%;
        border-left: 2px solid #e8e8e8;
      }

      .timeline-light-item-head {
        position: absolute;
        bottom: 4px;
        left: 15px;
        z-index: 1;
        width: 14px;
        height: 14px;
        background: #fff;
        border: 2px solid #1890ff;
        border-radius: 50%;
        transform: translate(-50%, 0);
      }

      .timeline-light-item-content {
        height: 2em;
        padding-top: .2em;
        margin-left: 30px;
        line-height: 3em;
      }
    }

    @each $color_name, $color in $mdb-colors{
      .timeline-light-item-#{$color_name} {
        .timeline-light-item-head {
          border-color: $color;
        }
      }
    }

    .timeline-light-item-loading {
      .timeline-light-item-head {
        border-top: 1px solid transparent;
        border-right: 1px solid transparent;
        animation: tl-v2-loadingCircle 1s 1s infinite linear;
      }
    }

    .timeline-light-item-icon {
      .timeline-light-item-head {
        top: 45%;
        width: 20px;
        height: 20px;
        font-size: 20px;
        background: transparent;
        border: none;
        i {
          background-color: #fff;
          border-radius: 50%;
        }
      }

      .timeline-light-item-tail {
        bottom: 15px;
        left: 14px;
        height: 100%;
      }

    }

    .timeline-light-item-dashed {
      .timeline-light-item-tail {
        border-left-style: dashed;
      }
    }

    li:first-child.timeline-light-item {

      .timeline-light-item-tail {
        height: 0;
      }
    }

    @keyframes tl-v2-loadingCircle {
      0% {

        transform: translate(-50%, 0) rotate(0deg);
      }

      100% {
        transform: translate(-50%, 0) rotate(360deg);
      }
    }
  }
}
