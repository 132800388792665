/* Materializecss Stepper - By Kinark 2016
// https://github.com/Kinark/Materialize-stepper
// CSS v2.1.3
*/

/* Validate.js FIX */

label.invalid {
  top: 50px !important;
  font-size: 12.8px;
  font-size: .8rem;
  font-weight: 500;
  color: #f00 !important;

  &.active {
    transform: translateY(0%) !important;
  }
}

/* Validate.js FIX */

ul.stepper {
  .wait-feedback {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .step {
    position: relative;
    list-style: none;

    &.feedbacking .step-new-content > *:not(.wait-feedback) {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
      opacity: .1;
    }

    &:not(:last-of-type).active {
      margin-bottom: 2.25rem;
    }

    &:before {
      position: absolute;
      top: .75rem;
      width: 1.75rem;
      height: 1.75rem;
      font-weight: 400;
      line-height: 1.75rem;
      color: #fff;
      text-align: center;
      content: counter(section);
      counter-increment: section;
      background-color: rgba(0, 0, 0, .3);
      border-radius: 100%;
    }

    &.active:before {
      background-color: #4285f4;
    }

    &.done:before {
      font-family: "Font Awesome 5 Pro", "Font Awesome 5 Free", sans-serif;
      font-size: 1rem;
      font-weight: 900;
      content: "\f00c";
      background-color: #00c851;
    }

    &.wrong:before {
      font-family: "Font Awesome 5 Pro", "Font Awesome 5 Free", sans-serif;
      font-size: 1.1rem;
      font-weight: 900;
      content: "\f071";
      background-color: #ff3547;
    }
  }

  > li:not(:last-of-type) {
    margin-bottom: .625rem;
    transition: margin-bottom .4s;
  }

  .step-title {
    display: block;
    padding: .9688rem 2.75rem 1.5rem 4rem;
    margin: 0 -1.3rem;
    cursor: pointer;

    &:after {
      position: absolute;
      display: block;
      font-size: .8rem;
      font-weight: 400;
      color: #424242;
      content: attr(data-step-label);
    }

    &:hover {
      background-color: rgba(0, 0, 0, .06);
    }
  }

  .step.active .step-title {
    font-weight: 500;
  }

  .step-new-content {
    position: relative;
    display: none;
    width: inherit;
    height: calc(100% - 132px);
    margin-right: 24px;
    margin-left: 41px;
    overflow: visible;
  }

  > .step {
    &:not(:last-of-type):after {
      position: absolute;
      top: 3.125rem;
      left: .8438rem;
      width: .0625rem;
      height: 40%;
      height: calc(100% - 38px);
      content: "";
      background-color: rgba(0, 0, 0, .1);
      transition: all .4s;
    }

    &.active:not(:last-child):after {
      height: 93%;
      height: calc(100% - 12px);
    }

    &[data-last="true"] {
      &:after {
        width: 0;
        height: 0;
      }

      margin-bottom: 0;
    }
  }

  .step-actions {
    display: flex;
    -webkit-box-pack: start;
    .btn:not(:last-child),
    .btn-flat:not(:last-child),
    .btn-large:not(:last-child) {
      margin-right: .3125rem;
    }
  }

  .step-new-content .row {
    margin-bottom: .4375rem;
  }

  .md-form {
    label {
      left: .875rem;
    }

    .validate {
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: 993px) {
  ul.stepper.horizontal {
    position: relative;
    display: flex;
    justify-content: space-between;
    min-height: 20rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-right: -1.5rem;
    margin-left: -1.5rem;
    overflow: hidden;

    &:before {
      position: absolute;
      left: -3px;
      width: 100%;
      min-height: 5.25rem;
      content: "";
      background-color: transparent;
      border-top-left-radius: 2px;
    }

    &:first-child {
      margin-top: -2.7rem;
    }

    .step {
      position: static;
      display: flex;
      align-items: center;
      width: 100%;
      height: 5.25rem !important;
      margin: 0;

      &:not(:last-of-type):after {
        position: static;
        display: inline-block;
        width: 100%;
        height: .0625rem;
        content: "";
      }
      &.active .step-title:before {
        background-color: #4285f4;
      }

      &.done .step-title:before {
        font-family: "Font Awesome 5 Pro", "Font Awesome 5 Free", sans-serif;
        font-size: 1rem;
        font-weight: 900;
        content: "\f00c";
        background: #00c851;
      }

      &.wrong .step-title:before {
        font-family: "Font Awesome 5 Pro", "Font Awesome 5 Free", sans-serif;
        font-size: 1.1rem;
        font-weight: 900;
        content: "\f071";
        background-color: #ff3547;
      }
      &:before {
        content: none;
      }

      .step-title:before {
        position: absolute;
        top: 1.7813rem;
        left: 1.1875rem;
        width: 1.75rem;
        height: 1.75rem;
        font-weight: 400;
        line-height: 1.75rem;
        color: #fff;
        text-align: center;
        content: counter(section);
        counter-increment: section;
        background-color: rgba(0, 0, 0, .3);
        border-radius: 100%;
      }
    }

    > .step {

      &:last-of-type,
      &[data-last="true"] {
        width: auto !important;
      }
    }

    > .step.active:not(:last-of-type):after {
      position: static;
      display: inline-block;
      width: 100%;
      height: .0625rem;
      content: "";
    }

    .step-title {
      display: inline-block;
      flex-shrink: 0;
      max-width: 13.75rem;
      height: 5.25rem;
      padding: 0 1.5625rem 0 4.0625rem;
      margin: 0;
      overflow: hidden;
      line-height: 5.25rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      -ms-flex-negative: 0;
    }

    & .step-title:after {
      top: .9375rem;
    }

    & .step-new-content {
      position: absolute;
      top: 6rem;
      left: 0;
      width: 100%;
      height: calc(100% - 84px);
      padding: 1.25rem 1.25rem 4.75rem 1.25rem;
      margin: 0;
      overflow-x: hidden;
      overflow-y: auto;
    }

    & .step-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      flex-direction: row-reverse;
      width: 100%;
      padding: 20px;

      .btn:not(:last-child),
      .btn-flat:not(:last-child),
      .btn-large:not(:last-child) {
        margin-right: 0;
        margin-left: .3125rem;
      }
    }
  }

  ul.stepper.horizontal .step-new-content,
  ul.stepper.horizontal .step-actions {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}
