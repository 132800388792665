// MDB New Light Skin
$new-skin: (
	"primary": #1266f1,
	"secondary": #aa66cc, /* do zmiany */
	"default": #2BBBAD, /* do zmiany */
	"danger": #ff3d71,
	"warning": #ffc94d,
	"success": #00d68f,
	"info": #42aaff,
	"dark": #262626,
	"light": #f2f2f2,
	"grey-dark": #4f4f4f,
	"grey-medium": #8d8d8d,
	"grey-light": #f8f9fe
);

// Set the color of the button and badge
@function set-text-color($value) {
	@if (lightness($value) > 80) {
		@return #4f4f4f; // Lighter backgorund, return dark color
	}

	@else {
		@return #fff; // Darker background, return light color
	}
}
// Placeholder
@mixin placeholder {
	&::placeholder {
		@content;
	}
}

.skin-light {
	@each $color, $value in $new-skin {
		&.color-#{$color},
		.color-#{$color},
		.badge-#{$color},
		.bg-#{$color} {
			background-color: $value !important;
		}
		.text-#{$color} {
			color: $value !important;
		}
		.border-#{$color} {
			border-color: $value !important;
		}
		.btn-#{$color} {
			color: set-text-color($value);
			background-color: $value !important;
		}
		.btn-outline-#{$color} {
			color: $value !important;
			background-color: transparent !important;
			border: 2px solid $value !important;
		}
	}
	.cold-grey {
    background-color: #ebedf7 !important;
	}
	.card {
		font-weight: 300;
		box-shadow: 0px 10px 11px 0px rgba(0,0,0,.09);
		border-radius: .375rem;
		&[class*="border"] {
			border: 1px solid map-get($new-skin, "grey-medium");
			box-shadow: none;
		}
		&.card-dashboard {
			.dropdown-toggle {
				&::after {
					display: none;
				}
			}
		}
		.card-body {
			.card-text {
				font-weight: 300;
				font-size: .9rem;
				color: #747373;
			}
			.card-title {
				font-weight: 700;
				color: #4f4f4f;
			}
		}
		&.card-dark {
			.card-header {
				border-color: rgba(255,255,255,.7);
			}
		}
	}
	.card-img, 
	.card-img-top {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
	}
	.card-title {
		font-weight: 700;
		color: #4f4f4f;
	}
	.card-header {
		background-color: #fff;
		border-bottom: 1px solid rgba(0,0,0,.05);
		padding: 1.25rem;
		&:first-child {
			border-radius: .375rem .375rem 0 0;
		}
	}
	.card-footer {
		border-bottom: 1px solid rgba(0,0,0,.05);
		&:last-child {
			border-radius: 0 0 .375rem .375rem;
		}
	}
	.md-accordion {
		.card {
			border-radius: 0;
			box-shadow: none;
		}
	}
	.btn-floating {
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.05);
	}
	.btn {
		// margin: 0 0 .1875rem 0;
		margin: 0;
		font-size: .75rem;
		padding: .75rem 1.5rem;
		font-weight: 500;
		border-radius: .25rem;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.05);
		&[class*="btn-outline-"] {
			padding-top: .63rem;
			padding-bottom: .63rem;
		}
		&:active,
		&.active {
			box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); 
		}
		&:hover,
		&:focus {
			box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
		}
		&.btn-sm {
			font-size: .75rem;
			padding: .25rem 1rem;
			&[class*="btn-outline-"] {
				padding-top: .1rem;
				padding-bottom: .1rem;
			}
		}
		&.btn-md {
			font-size: .75rem;
			padding: .5rem 1.25rem;
			&[class*="btn-outline-"] {
				padding-top: .4rem;
				padding-bottom: .4rem;
			}
		}
		&.btn-rounded {
			border-radius: 10rem;
		}
		&.btn-lg {
			font-size: .9rem;
			padding: 1rem 1.75rem;
			&[class*="btn-outline-"] {
				padding-top: .875rem;
				padding-bottom: .875rem;
			}
		}
		&.btn-link {
			box-shadow: none;
		}
	}
	.btn-light {
		color: map-get($new-skin, "grey-dark") !important;
		background-color: map-get($new-skin, "light") !important;
		box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1), 0 2px 10px 0 rgba(0,0,0,0.05) !important;

		&:hover {
			background-color: lighten(map-get($new-skin, "light"), 1%) !important;
		}

		&:focus,
		&:active,
		&.active {
			background-color: darken(map-get($new-skin, "light"), 1%) !important;
		}

		&.dropdown-toggle {
			background-color: map-get($new-skin, "light") !important;
			
			&:hover,
			&:focus {
				background-color: lighten(map-get($new-skin, "light"), 1%) !important;
			}
		}

		&:not([disabled]):not(.disabled):active,
		&:not([disabled]):not(.disabled).active,
		.show > &.dropdown-toggle {
			background-color: darken(map-get($new-skin, "light"), 20%) !important;
		}
	}
	a {
		&.text-white {
			&:hover {
				color: #d9d9d9 !important
			}
		}
	}
	.dropdown-menu {
		color: #212529;
		margin: .125rem 0 0;
		padding: 0;
		min-width: 12rem;
		border: 0;
		border-radius: .3375rem;
		box-shadow: 0 50px 100px rgba(50,50,93,.1), 0 15px 35px rgba(50,50,93,.15), 0 5px 15px rgba(0,0,0,.1);
		a,
		.dropdown-item {
			font-size: .875rem;
			padding: .5rem 1rem;
			color: #212529;
			font-weight: 400;
			&:first-of-type {
				border-top-left-radius: .3375rem;
				border-top-right-radius: .3375rem;
			}
			&:last-of-type {
				border-bottom-left-radius: .3375rem;
				border-bottom-right-radius: .3375rem;
			}
			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
				color: #16181b !important;
				background-color: #eee;
				box-shadow: none;
			}
		}
	}
	.dropdown {
		&.notifications-nav {
			.dropdown-menu {
				min-width: 19rem;
			}
		}
	}
	.chart {
		position: relative;
		height: 350px;
		&.narrower {
			height: 300px;
		}
	}
	.table {
		border-color: rgba(0, 0, 0, 0.05);
		&.table-admin {
			thead {
				th {
					border-bottom: 1px solid rgba(0, 0, 0, 0.05);
					border-top: none;
				}
			}
		}
		th, td {
			border-top: 1px solid rgba(0, 0, 0, 0.05);
		}
	}
	.list-group-flush {
		.list-group-item {
			border-color: rgba(0, 0, 0, 0.1);
		}
	}
	.avatar-group .avatar+.avatar {
		margin-left: -1rem;
	}
	.border-avatar {
		border: 2px solid #fff !important;
	}
	.form-check-input[type="checkbox"].filled-in:checked + label:after {
		background-color: #1266f1;
		border: 1px solid #1266f1;
	}
	.form-check-input[type="checkbox"].filled-in + label:after {
		border-radius: .25rem !important;
	}
	.form-check-input[type="checkbox"].filled-in:not(:checked) + label:after {
		border: 1px solid #ccc;
	}
	.checkbox-success-filled .form-check-input[type="checkbox"].filled-in:checked + label:after, 
	label.btn input[type="checkbox"].filled-in:checked + label:after {
		background-color: #00d68f !important;
		border: 1px solid #00d68f !important;
	}
	.checkbox-danger-filled .form-check-input[type="checkbox"].filled-in:checked + label:after, 
	label.btn input[type="checkbox"].filled-in:checked + label:after {
		background-color: #ff3547 !important;
		border: 1px solid #ff3547 !important;
	}
	.checkbox-info-filled .form-check-input[type="checkbox"].filled-in:checked + label:after, 
	label.btn input[type="checkbox"].filled-in:checked + label:after {
		background-color: #42aaff !important;
		border: 1px solid #42aaff !important;
	}
	.checkbox-warning-filled .form-check-input[type="checkbox"].filled-in:checked + label:after, 
	label.btn input[type="checkbox"].filled-in:checked + label:after {
		background-color: #ffc94d !important;
		border: 1px solid #ffc94d !important;
	}
	.task-done {
		text-decoration: line-through;
	}
	.font-smaller {
		font-size: .8rem;
	}
	.form-check-input[type="checkbox"] + label, 
	label.btn input[type="checkbox"] + label {
		height: 1.2rem !important;
		line-height: 1.2rem !important;
	}
	.checklist-item-checked .checklist-info * {
		text-decoration: line-through;
	}
	.double-nav {
		a {
			&.button-collapse {
				color: rgba(0, 0, 0, 0.6);
			}
		}
	}
	.side-nav {
		box-shadow: 0 0 16px 0 rgba(0,0,0,.05);
		background-color: #fff;
		.logo-wrapper {
			border-bottom: 1px solid rgba(0,0,0,.05);
			height: 5.05rem;
			a {
				height: 5.05rem;
			}
		}
		.search-form {
			border-top: 0;
			input[type="text"] {
				padding-left: 1.88rem;
				width: calc(100% - 1.88rem);
			}
			.md-form {
				input[type="text"] {
					border-bottom: 1px solid rgba(0,0,0,.05);
					color: #9e9e9e !important;
					font-weight: 400;
					@include placeholder {
						color: #6c757d !important;
						font-weight: 400;
					}

					&:focus {
						border-bottom: 1px solid #1266f1;
						box-shadow: none;
					}
				}
			}
		}
		.collapsible {
			a {
				height: 46px;
				font-weight: 400;
			}
			> li {
				a {
					&.collapsible-header {
						&.active {
							background: #f6f9fc;
							border-radius: .375rem;
							margin-right: .5rem;
							margin-left: .5rem;
							transition: all 0.3s linear;
						}
					}
				}
			}
			li {
				.collapsible-header {
					color: rgba(0,0,0,.6);
					font-size: .875rem;
					margin-right: .5rem;
					margin-left: .5rem;
				}
				a {
					&:hover {
						background-color: #f6f9fc;
						border-radius: .375rem;
						transition: all 0.3s linear;
					}
				}
				a:not(.collapsible-header) {
					color: rgba(0,0,0,.6);
					height: 36px;
					margin-right: .5rem;
					margin-left: .5rem;
					&:hover,
					&:active,
					&.active,
					&:focus {
						color: rgba(0,0,0,.8);
					}
					&:hover {
						background-color: #f6f9fc;
						border-radius: .375rem;
						margin-right: .5rem;
						margin-left: .5rem;
					}
				}
			}
			.fas:not(.rotate-icon),
			.fab:not(.rotate-icon),
			.far:not(.rotate-icon) {
				margin-right: 0;
				min-width: 1.8rem;
			}
		}
		.collapsible-body {
			a {
				background-color: transparent;
				padding-left: 49px;
			}
		}
		.fa-angle-down {
			&.rotate-icon {
				position: relative;
				margin-left: auto;
				margin-top: -1.55rem;
			}
		}
	}
	.navbar {
		box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
		background-color: #fff;
		@media (max-width: 767px) {
			padding-top: .408rem;
			padding-bottom: .408rem;
		}
		.navbar-brand {
			display: flex;
			align-items: center;
			@media (max-width: 767px) {
				padding-top: 0;
				padding-bottom: 0;
				font-size: inherit;
				.fab, 
				.far, 
				.fas {
					&.fa-3x {
						font-size: 2em;
					}
				}
			}
			@media (min-width: 768px) and (max-width: 1024px) {
				padding-top: 0;
				padding-bottom: 0;
				font-size: inherit;
			}
		}
		.navbar-nav {
			align-items: center;
			.nav-item {
				.nav-link {
					font-weight: 400;
					color: rgba(0,0,0,.6);
					&:hover {
						color: rgba(0,0,0,.8);
					}
				}
				&.active {
					>.nav-link {
						background-color: transparent;
						color: #1266f1;
					}
				}
			}
		}
		&.navbar-transparent {
			&:not(.top-nav-collapse) {
				background: transparent;
				.breadcrumb {
					.breadcrumb-item {
						a {
							color: #fff;
						}
						&:before {
							color: #fff;
						}
						&.active {
							color: #fff;
						}
					}
				}
				.btn-navbar {
					background-color: #fff!important;
					color: #4f4f4f!important;
					&[class*="btn-outline-"] {
						color: #fff!important;
						background-color: transparent!important;
						border: 2px solid #fff!important;
					}
				}
			}
			&.top-nav-collapse {
				background-color: #fff;
				.navbar-brand {
					color: rgba(0, 0, 0, 0.9);
				}
				.navbar-nav {
					.nav-item {
						&.active {
							>.nav-link {
								color: #1266f1;
								&:hover {
									color: #1266f1;
								}
							}
						}
						.nav-link {
							color: #6c757d;
							&:hover {
								color: #4f4f4f;
							}
						}
					}
				}
			}
			.navbar-brand {
				color: #fff;
			}
			.navbar-nav {
				.nav-item {
					&.active {
						>.nav-link {
							background-color: transparent;
							color: #fff;
							&:hover {
								color: #eee;
							}
						}
					}
					.nav-link {
						color: #fff;
						&:hover {
							color: #eee;
						}
					}
				}
			}
		}
		.breadcrumb {
			padding-top: 0;
			align-items: center;
			.breadcrumb-item {
				display: flex;
				align-items: center;
				font-weight: 400;
				&:before {
					color: #6c757d;
					padding-right: .2rem;
					padding-left: .2rem;
				}
				&.active {
					color: #6c757d;
					padding: .2rem .3rem;
					font-weight: 700;
					&:before {
						padding-right: .5rem;
					}
				}
				a {
					color: #6c757d;
					padding: .2rem .3rem;
				}
				+.breadcrumb-item {
					padding-left: 0;
				}
			}
			@media (max-width: 991px) {
				display: none;
			}
		}
	}
	@media (max-width: 991px) {
		.navbar {
			&.navbar-transparent {
				&:not(.top-nav-collapse) { 
					background: #fff;
					.btn-navbar {
						background-color: #42aaff!important;
						color: #fff!important;
						&[class*="btn-outline-"] {
							color: #42aaff!important;
							background-color: transparent!important;
							border: 2px solid #42aaff!important;
						}
					}
				}
				.navbar-brand {
		  		color: rgba(0, 0, 0, 0.9);
				}
				.navbar-nav {
					.nav-item {
						.nav-link {
							color: #6c757d;
							&:hover {
								color: #4f4f4f;
							}
						}
						&.active {
							>.nav-link {
								color: #1266f1;
								&:hover {
									color: #1266f1;
								}
							}
						}
					}
				}
			}
		}
	}
	.mb-01 {
		margin-bottom: .12rem;
	}
	.pt-12 {
		padding-top: 1.2rem !important;
	}
	.pt-17 {
		padding-top: 1.7rem !important;
	}
	.pt-18 {
		padding-top: 1.8rem !important;
	}
	.w-40 {
		width: 40%;
	}
	.mt-94 {
		margin-top: 94px;
		@media (max-width: 767px) {
			margin-top: 51px;
		}
		@media (min-width: 768px) and (max-width: 1024px) {
			margin-top: 72px;
		}
	}
	.icon-admin {
		width: 60px; 
		height: 60px;
		>i {
			width: 22px;
			height: 22px;
			line-height: 60px;
			&::before {
				width: 22px;
				height: 22px;
			}
		}
	}
	.icon-bgc {
		background-color: #1266f1;
		text-align: center;
		width: 50px;
		height: 50px;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.09);
	}
	.wh-20 {
		width: 20px;
		height: 20px;
		line-height: 50px;
		font-size: 1.25rem;
	}
	.z-depth-1 {
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.09) !important;
	}
	.z-depth-1-half {
		box-shadow: 0px 10px 11px 0px rgba(0,0,0,.09) !important;
	}
	.z-depth-2 {
		box-shadow: 0px 10px 11px 0px rgba(0,0,0,.05), 0px 10px 11px 0px rgba(0,0,0,.05) !important;
	}
	.z-depth-5 {
		box-shadow: 0px 15px 45px -9px rgba(0,0,0,.2) !important;
	}
	a {
		&.card-link {
			color: #4f4f4f;
			&:hover {
				color: #1266f1;
			}
		}
		&.card-link-2 {
			color: #6c757d;
			&:hover {
				color: #4f4f4f;
			}
		}
		&.card-link-3 {
			color: #fff;
			&:hover {
				color: #ccc;
			}
		}
		&.card-link-4 {
			color: #fff;
			&:hover {
				color: #1266f1;
			}
		}
	}
	.media-comment-text {
		position: relative;
		border-radius: .4375rem;
		background-color: #f4f4f4;
	}
	hr {
		border-top: 1px solid rgba(0,0,0,0.1);
	}
	.price {
		position: relative;
		.number {
			font-size: 4rem;
			font-weight: 300;
			&::before {
				position: absolute;
				margin-left: -1.2rem;
				font-size: 1.88rem;
				content: "$";
			}
			&::after {
				position: absolute;
				margin-top: 1.9rem;
				font-size: 1.88rem;
				content: "/mo";
			}
		}
	}
	@media (max-width: 992px) {
		.profile-card-new {
			margin-top: 120px;
		}
	}
	.profile-card-new .avatar-o {
		max-width: 150px;
		max-height: 150px;
		margin-top: -70px;
		margin-left: auto;
		margin-right: auto;
		-webkit-border-radius: 50%;
		border-radius: 50%;
		overflow: hidden;
	}
	.avatar-o img.rounded-circle {
		max-width: 150px;
	}
	
	.avatar-o img {
		max-width: 200px;
		height: auto;
	}
	@media (min-width: 1920px) {
		.mb-xl-180 {
			margin-bottom: 179px;
		}
	}
	strong {
		font-weight: 500;
	}
	.badge-news {
		position: absolute;
		top: 10px;
		left: 10px;
	}
	.badge-news-3 {
		position: absolute;
	top: 10px;
	left: 25px;
	}
	.badges-category {
		.badge {
			color: #666 !important;
			box-shadow: none;
		}
	}
	.link-cat {
		color: #6c757d;
		&:hover {
			color: #1266f1;
			transition: all 0.2s ease-in-out;
		}
	}
	.foot-desc {
		line-height: 1.8;
		font-weight: 300;
	}
	.hr-grey {
		border-top: 1px solid rgba(255,255,255, 0.3) !important;
	}
	.hr-dark-grey {
		border-top: 1px solid rgba(0,0,0,0.1);
	}
	.font-change {
		font-size: 1rem;
	}
	.post-page {
		p {
			font-weight: 300;
		}
	}
	.breadcrumb-bgc {
		background-color: #fbfbfb;
	}
	.breadcrumb-bgc .breadcrumb-item + .breadcrumb-item::before {
		font-weight: 300;
		content: ">";
	}
	.hr-card {
		width: calc(100% - 57px);
	}
	.mt-n56 {
		margin-top: -3.1rem;
	}
	a {
		&.footer-link {
			p {
				color: #9e9e9e;
				transition: all 0.2s ease-in-out;
			}
			&:hover {
				p {
					color: #4f4f4f;
					transition: all 0.2s ease-in-out;
				}
			}
		}
	}
	.card {
		>.view {
			&.zoom {
				border-top-left-radius: .375rem;
				border-top-right-radius: .375rem;
			}
		}
	}
	.card-img-70 {
		width: 70px;
		height: auto;
	}
	.media-link {
		color: #4f4f4f;
		&:hover {
			color: #6c757d;
		}
	}
	.alert {
		&.alert-grey {
			background-color: #f4f4f4;
		}
	}
	.number-input input[type=number]::-webkit-inner-spin-button,
	.number-input input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}
	.number-input input[type=number] {
		text-align: center;
	}
	.number-input.number-input input[type=number] {
		max-width: 4rem;
		padding: .5rem;
		border: 1px solid #ced4da;
		border-width: 0 1px;
		border: none;
		font-size: 1rem;
		height: 2rem;
		color: #495057;
	}
	.classic-tabs {
		.nav {
			li {
				a {
					&.active {
						color: #1266f1 !important;
					}
				}
			}
			&.tabs-dark-blue {
				background-color: #fff;
			}
			&.tabs-animated {
				li {
					a {
						&.active {
							border: none;
						}
					}
				}
				&.tabs-dark-blue {
					.floor {
						background-color: #1266f1;
					}
				}
				.floor {
					display: inline-block;
					width: 30px;
					height: 4px;
					border-radius: 20px;
					position: absolute;
					z-index: 1200;
					bottom: 0;
					-webkit-transition: all .4s linear;
					-o-transition: all .4s linear;
					transition: all .4s linear;
				}
			}
			.nav-link {
				&.active {
					background-color: #fff;
				}
			}
		}
	}
	.w-150 {
		width: 150px;
	}
}